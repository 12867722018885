import type { FunctionComponent } from 'react'

import Title, { TitleElement, TitleSize } from '@sporza/design-system/atoms/title'
import Cell from '@sporza/design-system/organisms/cell'
import Grid from '@sporza/design-system/organisms/grid'
import { ComponentType, mapToComponent } from '@sporza/services/component-mapper'
import clsx from 'clsx'

import { PageType } from '~/config'
import { Layout } from '~/layouts'

import styles from './default.module.scss'

interface DefaultLayoutProps {
  title: string,
  header: any[],
  main: any[],
  mainBody: any[],
  aside: any[],
  apiBaseUrl?: string,
  designSystemBaseUrl?: string,
  bffBaseUrl?: string,
  classNames?: string
  pageType?: string
}

const DefaultLayout: FunctionComponent<DefaultLayoutProps> = (
  {
    title,
    header: headerItems = [],
    main: mainItems = [],
    mainBody: mainBodyItems = [],
    aside: asideItems = [],
    apiBaseUrl,
    designSystemBaseUrl,
    bffBaseUrl,
    classNames,
    pageType
  }
) => {
  const headerChildren = !!headerItems?.length && <Grid>
    <Cell size={4}>
      {headerItems.map((item: any) => mapToComponent(item, { apiBaseUrl, designSystemBaseUrl, bffBaseUrl }))}
    </Cell>
  </Grid>

  const showTitle = !headerItems?.some((item: any) => item.componentType === ComponentType.Bio)
    && pageType != PageType.Promo

  return <Layout
    headerChildren={headerChildren}
    className={clsx(
      classNames
    )}
  >
    <Grid collapse>
      <Cell size={asideItems?.length ? 3 : 4}>
        <main>
          {showTitle && <Title size={TitleSize.Large} tag={TitleElement.H1}>{title}</Title>}
          {
            [
              ...mainItems,
              ...mainBodyItems
            ].map((item: any) => mapToComponent(item, { apiBaseUrl, designSystemBaseUrl, bffBaseUrl }))
          }
        </main>
      </Cell>
      {!!asideItems?.length && <Cell size={1}>
        <aside>
          {asideItems.map((item: any) => mapToComponent(item, { apiBaseUrl, designSystemBaseUrl, bffBaseUrl }))}
        </aside>
      </Cell>}
    </Grid>
  </Layout>
}

export {
  DefaultLayout
}
